import(/* webpackMode: "eager", webpackExports: ["BreadcrumbWrapper"] */ "/vercel/path0/libs/apps/marketing-website/ui/breadcrumb-container/src/lib/breadcrumb-wrapper/breadcrumb-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeeCheckerBlock"] */ "/vercel/path0/libs/apps/marketing-website/ui/fee-checker-block/src/lib/fee-checker-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeadCaptureFormBlockContainer"] */ "/vercel/path0/libs/apps/marketing-website/ui/lead-capture-form-block-container/src/lib/lead-capture-form-block-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewBlock"] */ "/vercel/path0/libs/apps/marketing-website/ui/review-block/src/lib/review-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmbeddedComponentBlock"] */ "/vercel/path0/libs/shared/ui/blocks/embedded-component-block/src/lib/embedded-component-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FAQBlock"] */ "/vercel/path0/libs/shared/ui/blocks/faq-block/src/lib/faq-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MortgageCalculatorBlock"] */ "/vercel/path0/libs/shared/ui/blocks/mortgage-calculator-block/src/lib/mortgage-calculator-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StampDutyCalculatorBlock"] */ "/vercel/path0/libs/shared/ui/blocks/stamp-duty-calculator-block/src/lib/stamp-duty-calculator-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Icon"] */ "/vercel/path0/libs/shared/ui/common/icon/src/lib/icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/libs/shared/ui/common/image/src/lib/image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsContent","TabsTrigger"] */ "/vercel/path0/libs/shared/ui/common/tabs/src/lib/tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HousePriceIndex"] */ "/vercel/path0/libs/shared/ui/tools/house-price-index/src/lib/house-price-index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HousePricesOverTime"] */ "/vercel/path0/libs/shared/ui/tools/house-prices-over-time/src/lib/house-prices-over-time.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SavingsCalculator"] */ "/vercel/path0/libs/shared/ui/tools/savings-calculator/src/lib/savings-calculator.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
